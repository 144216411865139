@mixin material-overrides() {
	.ks-modal-gallery-backdrop {
		background: #000 !important;
		opacity: 0.7 !important;
	}

	.ks-modal-gallery-panel {
		z-index: 90000 !important;
	}

	#current-figure > #current-image {
		max-height: 80vh;
	}

	.images {
		span {
			color: nb-theme(form-title);
		}
	}

	.overview-image {
		max-height: 335px;
	}

	.mat-menu-panel {
		min-height: auto;
	}

	@include nb-for-themes(material-dark) {
		.mat-datetimepicker-calendar-content {
			background-color: #424242;

			.mat-datetimepicker-calendar-body-selected,
			.mat-datetimepicker-clock-cell-selected {
				color: #000000de !important;
			}

			.mat-datetimepicker-calendar-table-header,
			.mat-datetimepicker-calendar-body-cell-content {
				color: #fff;
			}

			.mat-datetimepicker-clock {
				background-color: #424242;
				color: #fff;

				.mat-datetimepicker-clock-cell:not(.mat-datetimepicker-clock-cell-disabled) {
					color: #fff;
				}
			}
		}

		.mat-snack-bar-container {
			color: rgba(255, 255, 255, 0.7);
			background: #323232;
		}

		.mat-tree {
			background: unset;
		}

		.table-striped > tbody > tr:nth-of-type(odd) > * {
			--bs-table-accent-bg: var(--bs-table-striped-bg);
			color: #fff;
		}

		.mat-row.disabled td:not(.cdk-column-actions) {
			color: #727272;
		}

		.text-disabled {
			color: #727272;
		}

		// Datepicker styling
		.md-drppicker {
			$dark-bg-background: #353535;

			color: #fff;
			background-color: $dark-bg-background;

			.calendar-table {
				background-color: $dark-bg-background;

				th.available {
					filter: invert(1);

					&:hover {
						background-color: rgba(255, 255, 255, 0.1);
					}
				}

				td {
					&.start-date:not(.off),
					&.end-date:not(.off) {
						background-color: #e93d00 !important;
						color: #000;
					}

					&.off {
						color: #999;
					}

					&.in-range {
						color: #000;
						background-color: #f8f9fa;
					}

					&.available:not(.in-range):hover {
						background-color: rgba(255, 255, 255, 0.1);
					}
				}
			}

			.calendar-time {
				.select-item {
					background-color: $dark-bg-background;
					color: #fff;
					border: none;
					border-bottom: 1px solid rgba(255, 255, 255, 0.12);
				}
			}

			.ranges {
				li:hover {
					background-color: rgba(255, 255, 255, 0.04);
				}

				button {
					color: #fff;

					&.active {
						background-color: #f8f9fa;
						color: #000;
					}
				}
			}
		}
	}

	@include nb-for-themes(material-light) {
		.mat-snack-bar-container {
			color: rgba(0, 0, 0, 0.87);
			background: #fafafa;
		}

		.mat-row.disabled td:not(.cdk-column-actions) {
			color: #cccccc;
		}

		.text-disabled {
			color: #cccccc;
		}

		nb-menu[ng-reflect-tag='user-menu'] .menu-item .menu-title {
			color: #424242;
		}

		// Datepicker styling
		.md-drppicker {
			$light-bg-background: #fff;

			color: #424242;
			background-color: $light-bg-background;

			.calendar-table {
				background-color: $light-bg-background;

				td {
					&.start-date:not(.off),
					&.end-date:not(.off) {
						background-color: #e93d00 !important;
						color: #fff;
					}

					&.off {
						color: #999;
					}

					&.in-range {
						color: #424242;
						background-color: #dde2e4;
					}

					&.available:not(.in-range):hover {
						background-color: #eee;
					}
				}
			}

			.calendar-time {
				.select-item {
					background-color: $light-bg-background;
					color: #424242;
					border: none;
					border-bottom: 1px solid rgba(0, 0, 0, 0.12);
				}
			}

			.ranges {
				li:hover {
					background-color: rgba(0, 0, 0, 0.04);
				}

				button {
					color: #424242;

					&.active {
						background-color: #e93d00;
						color: #fff;
					}
				}
			}
		}
		// TODO: This is for .select-item bottom border
		//
	}

	@include nb-for-themes(material-dark, material-light) {
		.mat-progress-spinner circle,
		.mat-spinner circle {
			stroke: #e93d00 !important;
		}

		.mat-card-title {
			font-size: 18px;
		}

		.btn-small {
			font-size: 12px;
			line-height: 25px;
		}

		a {
			color: #f35300;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
				color: #c84300;
			}
		}

		.mat-datetimepicker-calendar-header {
			background-color: #f35300;
		}

		.layout nb-layout-header nav {
			background: linear-gradient(to left, #e63500 0%, #ff6d00 100%) repeat-x;
		}

		.mat-raised-button:not([disabled]).mat-primary {
			background: linear-gradient(to right, #dc7531 0%, #ce4409 100%) repeat-x !important;

			&:hover {
				background: linear-gradient(to right, #f77e2f 0%, #ff540a 100%) repeat-x !important;
			}
		}

		.mat-button-toggle:not(.mat-button-toggle-disabled) {
			border: 1px solid #e93d00 !important;
			color: #e93d00;

			&.mat-button-toggle-checked {
				background: #f35300;
				color: #fff;
			}
		}

		.mat-stroked-button:not([disabled]) {
			border: 1px solid #e93d00 !important;
			color: #e93d00;

			&:hover {
				background: #ffeee2;
			}
		}

		.mat-raised-button:not([disabled]).mat-warn {
			background-color: #f05050 !important;

			&:hover {
				background-color: #ec2121 !important;
			}
		}

		.mat-simple-snackbar-action button {
			background-color: #f05050 !important;
			// border-color: #4cae4c;
			color: white;

			&:hover {
				background-color: #ec2121 !important;
			}
		}

		button.mat-success {
			background-color: #5cb85c;
			border-color: #4cae4c;
			color: white;
		}

		nb-sidebar {
			.menu-item {
				border: none;

				a.active,
				a:hover {
					color: #f05050 !important;

					.menu-icon {
						color: #f05050 !important;
					}
				}

				.menu-item {
					&:first-child {
						border-top: none;
					}
				}
			}
		}

		nb-layout-header {
			nb-actions,
			.logo-container {
				nb-icon,
				.user-name {
					color: nb-theme(color-basic-100) !important;
				}
			}

			.select-button {
				background-color: nb-theme(background-basic-color-3) !important;
			}
		}

		nb-sidebar {
			transition: width 0.3s;

			.main-container {
				transition: width 0.3s;
			}
		}

		nb-card {
			border-bottom-left-radius: 0.125rem;
			border-bottom-right-radius: 0.125rem;

			nb-card-header,
			.tabset {
				background-color: nb-theme(card-divider-color);
			}
		}

		[nbinput] {
			font-weight: 400;

			&.status-basic:focus:hover {
				border-color: nb-theme(color-primary-focus) !important;
			}
		}

		[nbbutton] {
			box-shadow: none !important;
		}
	}
}

.table-icon {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	object-fit: cover;
	border: 1px solid black;
}

.mat-vertical-content {
	padding: 15px 24px 24px 24px !important;
}

.nb-theme-material-light
	.mat-checkbox-indeterminate.mat-accent
	.mat-checkbox-background,
.nb-theme-material-light
	.mat-checkbox-checked.mat-accent
	.mat-checkbox-background {
	background-color: #f35300 !important;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
	background-color: #f35300 !important;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
	color: #f35300 !important;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
	background-color: #f35300 !important;
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
	color: #f35300 !important;
}
.pace .pace-progress {
	background-color: #fff !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
	background-color: #f35300 !important;
}
.mat-form-field.mat-focused .mat-form-field-label {
	color: #f35300 !important;
}

#html
	.nb-theme-material-dark
	.mat-slide-toggle.mat-checked
	.mat-slide-toggle-thumb {
	background: #fff !important;
}
#html
	.nb-theme-material-dark
	.mat-slide-toggle.mat-checked
	.mat-slide-toggle-bar {
	background: #00000061 !important;
}
.mat-placeholder-required,
.mat-form-field-required-marker {
	color: #f35300 !important;
}
.nb-theme-material-light .mat-mini-fab.mat-primary {
	background: linear-gradient(to right, #dc7531 0%, #ce4409 100%) repeat-x !important;
}
.nb-theme-material-light .mat-icon.mat-primary {
	color: #f35300 !important;
}
.nb-theme-material-dark .mat-icon.mat-primary {
	color: #f35300 !important;
}
.nb-theme-material-light .mat-icon.mat-primary.disabled {
	color: rgba(0, 0, 0, 0.3) !important;
}
.nb-theme-material-dark .mat-icon.mat-primary.disabled {
	color: rgba(0, 0, 0, 0.3) !important;
}
.nb-theme-material-light a:focus {
	color: #f35300 !important;
}

.nb-theme-material-light {
	.store-game {
		color: #000;
	}
}

.nb-theme-material-dark {
	.store-game {
		color: #fff;
	}
}

.nb-theme-material-dark .ngx-json-viewer .segment .segment-main .segment-key {
	color: #fff !important;
}

.nb-theme-material-dark .mat-step-header .mat-step-icon-selected,
.nb-theme-material-dark .mat-step-header .mat-step-icon-state-done,
.nb-theme-material-dark .mat-step-header .mat-step-icon-state-edit {
	background-color: #f35300 !important;
}
.nb-theme-material-light .mat-step-header .mat-step-icon-selected,
.nb-theme-material-dark .mat-step-header .mat-step-icon-state-done,
.nb-theme-material-dark .mat-step-header .mat-step-icon-state-edit {
	background-color: #f35300 !important;
}
