/*@noflip*/
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #fd7e14;
	background-image: -webkit-linear-gradient(90deg, #fd7e14 10%, #f57102 90%);
	/* Chrome 10+, Saf5.1+ */
	background-image: -moz-linear-gradient(90deg, #fd7e14 10%, #f57102 90%);
	/* FF3.6+ */
	background-image: -ms-linear-gradient(90deg, #fd7e14 10%, #f57102 90%);
	/* IE10 */
	background-image: -o-linear-gradient(90deg, #fd7e14 10%, #f57102 90%);
	/* Opera 11.10+ */
	background-image: linear-gradient(90deg, #fd7e14 10%, #f57102 90%);
	/* W3C */
	z-index: 9999;
	transition: opacity 0.65s;
}
.preloader * {
	transition: 0.3s all ease-in-out;
}

/*@noflip*/
.preloader-progress {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 300px;
	height: 300px;
	margin: auto;
	overflow: auto;
	background-size: 300px 300px;
}

/*@noflip*/
.preloader-progress-bar {
	position: absolute;
	top: 0;
	left: 50%;
	bottom: 0;
	min-width: 0%;
	background-image: url(https://staging.app.fireballserver.com/assets/admin/logo/fireball_logo.png);
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	transform: translate(-50%);
}

.preloader-hidden {
	display: none;
}

.preloader-hidden-add {
	opacity: 1;
	display: block;
}
.preloader-hidden-add .preloader-progress {
	transition: transform 0.4s ease;
	transform: scale(0);
}

.preloader-hidden-add-active {
	opacity: 0;
}
