@import './themes';

@mixin nb-overrides() {
	mat-option.option-container {
		height: unset;
	}

	.mat-option .mat-icon {
		margin-right: 0;
	}

	.mat-optgroup-label {
		line-height: 3em !important;
	}

	.mat-icon-button.small {
		width: 25px;
		height: 25px;
		line-height: 25px;

		mat-icon {
			width: 17px;
			height: 17px;
			font-size: 17px;
			line-height: 17px !important;
		}
	}

	.cdk-overlay-backdrop {
		&.backdrop-blur {
			backdrop-filter: blur(3px);
		}

		&.popover-backdrop {
			background: rgba(0, 0, 0, 0.32);
		}
	}

	.table-hover > tbody > tr:hover > * {
		color: inherit;
	}

	mat-chip {
		font-size: 13px;
		min-height: 22px;
	}

	ngx-dropzone {
		background: unset;
		color: unset;
	}

	ngx-dropzone-label {
		padding: 0 10px;
	}

	// Radio buttons fix
	.mat-radio-button:not(.mat-radio-disabled) {
		.mat-radio-outer-circle {
			border-color: #dc7531 !important;
		}

		.mat-radio-inner-circle {
			background-color: #dc7531 !important;
		}
	}
	// Radio buttons fix

	.mat-slide-toggle.mat-checked {
		.mat-slide-toggle-bar,
		.mat-slide-toggle-thumb {
			background: linear-gradient(to right, #dc7531 0%, #ce4409 100%) repeat-x !important;
		}
	}

	.email-cell {
		opacity: 0.8;
		font-size: 0.9rem;

		a {
			color: unset !important;
			text-decoration: none !important;
		}
	}

	.invite-menu,
	.widget-menu {
		.mat-menu-item {
			height: 32px;
			line-height: 32px;
		}
	}

	.invite-menu .mat-menu-item {
		display: flex;
		align-items: center;
	}

	.organization-menu {
		max-width: 330px;

		.mat-menu-content {
			padding: 0;
		}

		&.multiple {
			.menu-item {
				cursor: pointer;
			}
		}

		.settings-item {
			line-height: 24px;
			padding: 6px 16px;
			height: auto;
			cursor: default;
		}

		.menu-wrapper {
			display: flex;
			line-height: 24px;
			padding: 6px 16px;
			padding-left: 0;
			height: auto;
			cursor: default;
			border-left: 3px solid transparent;

			.image-wrapper {
				.no-logo-wrapper {
					width: 30px;
					height: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-inline: auto;
					border: 2px solid #ebebeb;
					border-radius: 50%;
					font-size: 12px;
				}
			}

			&.selected {
				border-left: 3px solid #f05050;
				background-color: #f8f9fa;
				color: #000;
			}

			.menu-item {
				display: flex;
				flex-direction: column;

				.roles,
				.name {
					max-width: 270px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				.name {
					font-size: 17px;
					font-weight: 500;
				}
			}
		}
	}

	.mat-column-actions .deactivate {
		width: 125px;
	}

	nb-select.size-medium button {
		padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

		nb-icon {
			right: 0.41rem !important;
		}
	}

	nb-card {
		margin-bottom: 0;

		nb-card-header {
			.subheader {
				line-height: 1rem;
				font-weight: 400;
				font-size: 0.85rem;
			}
		}

		nb-card-body {
			padding: 0;
		}
	}

	mat-checkbox {
		display: flex;
		align-items: center;

		label {
			margin-bottom: 0;
			margin-right: 0.5rem;
		}
	}

	nb-card nb-list,
	ngx-dropzone,
	.mat-menu-panel,
	.mat-select-panel,
	.cdk-virtual-scroll-viewport,
	textarea {
		@include nb-scrollbars(
			nb-theme(card-scrollbar-color),
			nb-theme(card-scrollbar-background-color),
			nb-theme(card-scrollbar-width)
		);
	}

	.mat-tooltip.larger {
		font-size: 14px !important;
	}

	.table {
		color: nb-theme(text-basic-color) !important;
	}

	.mat-dialog-container {
		padding: 0;
	}
}

#html
	.nb-theme-material-light
	.mat-checkbox-indeterminate.mat-accent
	.mat-checkbox-background,
.nb-theme-material-light
	.mat-checkbox-checked.mat-accent
	.mat-checkbox-background {
	background-color: #f35300;
}

.nb-theme-material-dark {
	.us-link__text,
	.us-profile {
		color: #fff !important;
	}
}
.nb-theme-material-dark {
	.assets-container {
		background: #404040 !important;
	}
}
.nb-theme-material-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background: #fff !important;
}
.nb-theme-material-dark .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background: #00000061 !important;
}
