@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,600,700,800&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/utilities/api';

@import './material/angular-material';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';
@import './font';
@import './material/material-overrides';

// install the framework and custom global styles
@include nb-install() {
	@include angular-material();

	// framework global styles
	@include nb-theme-global();

	@include ngx-layout();
	// loading progress bar
	@include ngx-pace-theme();

	@include nb-overrides();
	@include material-overrides();
}
